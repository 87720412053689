import { extendTheme, theme as baseTheme } from '@chakra-ui/react'

import { Avatar } from './avatar'
import { Button } from './button'
import { Container } from './container'
import { Heading } from './heading'
import { Link } from './link'
import { Text } from './text'

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
baseTheme.components.Heading.sizes = undefined as any
baseTheme.components.Input.sizes = undefined as any
baseTheme.components.Avatar.sizes = undefined as any

const activeLabelStyles = {
  transform: 'scale(0.9) translateY(-16px)',
}

export const theme = extendTheme({
  components: {
    Avatar,
    Button,
    Container,
    Text,
    Heading,
    Link,
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
              input: {
                paddingTop: '0px',
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
              ...activeLabelStyles,
            },
            input: {
              paddingTop: '10px',
            },
            label: {
              top: '-2px',
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              fontSize: '12px',
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        color: 'gray.DEFAULT',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `innocent-headline_bold, ${baseTheme.fonts.heading}`,
    body: `innocent-headline_bold, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '2.25rem',
    '4xl': '2.75rem',
    '6xl': '3.5rem',
    '7xl': '4.5rem',
  },
  lineHeights: {
    md: '1,75rem',
  },
  colors: {
    transparent: {
      '500': '#FFF0',
    },
    bg: {
      '90': '#1F2D2B',
      '0': 'white',
      '900': 'black',
    },
    primary: {
      DEFAULT: '#E87722',
      SECOND: '#00966C',
      TERTIARY: '#74AA50',
      HOVER: '#FF9E1B',
      DISABLED: '#FFC9A0',
      LIGHTEN: '#CCD9D5',
    },
    secondary: {
      DEFAULT: '#E2E868',
      SECOND: '#00966C',
      TERTIARY: '#00573F',
    },
    tertiary: {
      DEFAULT: '#638DFF',
      SECOND: '#713DFF',
    },
    accent: {
      ERROR: '#F64C46',
      SUCCESS: '#00B077',
      WARNING: '#FFF056',
    },
    primary_grey: {
      DEFAULT: '#F6F5F1',
    },
    text: '#2A2A2A',
    gray: {
      DEFAULT: '#757575',
      '0': '#757575',
      '100': '#C4C4C4',
      '200': '#212721',
    },
    white: {
      DEFAULT: '#fff',
      15: 'rgba(255, 255, 255, 0.15)',
    },
  },
})
