import { Box, Flex, Input, Text, Link as ExternalLink, FormErrorMessage, FormControl } from '@chakra-ui/react'
import { useState } from 'react'

import { MIIMOSA_CONDITIONS_URL, MIIMOSA_LEGAL_URL, preventingDefault, s3ImageURL } from '@miimosa/common'
import type { UserProposition } from '@miimosa/common/types'
import { Button } from '@miimosa/design-system/components'
import { BoxedContainer, FullWidthContainer, Image, Link, LinkV2 } from '@miimosa/design-system/components'

const Footer = () => {
  const [email, setEmail] = useState<string>()
  const [error, setError] = useState<string | null>()
  const [showThanks, setShowThanks] = useState<boolean | null>()
  const handleChange = (v: string) => {
    setShowThanks(false)
    setError(null)
    setEmail(v)
  }

  const handleSubscribe = async () => {
    setError(null)
    setShowThanks(false)
    const res = await fetch(`/nextapi/projects/teasing`, {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        email,
        platform_country: 'innocent_fr',
        campaign_slug: 'innocent-users',
      } as UserProposition),
    })

    if (res.status === 200) {
      setShowThanks(true)
      setEmail('')
    } else {
      setError("Oups, une erreur s'est produite, veuillez réessayer")
    }
  }

  return (
    <footer>
      <FullWidthContainer bgColor="secondary.SECOND" align="center" py="12" mb="-40px">
        <Flex direction="column" width={{ base: '335px', md: '475px' }}>
          <Text size="lg" textAlign={{ base: 'center', md: 'left' }} color="white" mb="8">
            Vous souhaitez vous tenir au courant de la grande classe verte ? Laissez-nous votre mail nous vous enverrons
            des nouvelles, promis
          </Text>
          <Flex
            as="form"
            onSubmit={preventingDefault(handleSubscribe)}
            display="flex"
            justify="space-between"
            align="center"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <FormControl isRequired isInvalid={!!error}>
              <Input
                type="email"
                placeholder="email@exemple.com"
                focusBorderColor="bg.0"
                color="bg.0"
                pb="10px"
                variant="flushed"
                borderColor="bg.0"
                width={{ base: '100%', md: '290px' }}
                onChange={(e) => handleChange(e?.target?.value)}
                value={email}
                fontFamily="innocent-headline_thin"
                _placeholder={{ color: 'bg.0', fontWeight: 400, fontFamily: 'innocent-headline_thin' }}
              />
              {showThanks && (
                <Text color="white" size="xs" fontWeight="bold" mt="2">
                  Merci pour votre inscription !
                </Text>
              )}
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
            <Button type="submit" mt={{ base: '3', md: 0 }} variant="primaryW" size="md">
              s&apos;abonner
            </Button>
          </Flex>
        </Flex>
      </FullWidthContainer>
      <FullWidthContainer position="relative" height="40px">
        <Image src={s3ImageURL('innocent-wave-green.svg')} alt="" fill />
      </FullWidthContainer>
      <FullWidthContainer bgColor="secondary.TERTIARY" align="center" direction="column">
        <BoxedContainer align="left" bgColor="secondary.TERTIARY" py="4">
          <Box width="176px" display="block" height="78px" transform="scale(1, 1)" ml="16" mb="10" position="relative">
            <Image alt="innocent" src={s3ImageURL('inno-logo-footer-white.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Flex direction="column" width="100%" align="center">
            <Flex
              bgColor="secondary.TERTIARY"
              direction="column"
              width={{ base: '90%', md: ' 736px' }}
              justify="center"
            >
              <Flex>
                <Link size="lg" href="/projects" color="white" mr="8">
                  projets
                </Link>
                <Link size="lg" href="/manifest" color="white" mr="8">
                  notre manifeste
                </Link>
                <LinkV2 size="lg" path="/faq" color="white">
                  FAQ
                </LinkV2>
              </Flex>
              <Box height="2px" my="6" bgColor="secondary.SECOND" width="100%" />
              <Flex>
                <ExternalLink size="2xs" color="white" mr="6" href={MIIMOSA_CONDITIONS_URL}>
                  CGU MiiMOSA
                </ExternalLink>
                <ExternalLink size="2xs" color="white" mr="6" href={MIIMOSA_LEGAL_URL}>
                  Mentions légales
                </ExternalLink>
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" alignSelf="center" justify="space-between" width="126px" mt="16" mb="8">
            <ExternalLink variant="ghost" href="https://fr-fr.facebook.com/innocentfrance/">
              <Image src={s3ImageURL('facebook.png')} alt="fb" width="31" height="30" />
            </ExternalLink>
            <ExternalLink variant="ghost" href="https://www.instagram.com/innocentfrance/">
              <Image src={s3ImageURL('instagram.png')} alt="instagram" width="30" height="30" />
            </ExternalLink>
            <ExternalLink variant="ghost" href="https://twitter.com/innocent_fr">
              <Image src={s3ImageURL('tw.png')} alt="twitter" width="32" height="25" />
            </ExternalLink>
          </Flex>
          <Text color="white" fontSize="12px" textAlign="center" mb="4">
            © 2023 MiiMOSA. All rights reserved.
          </Text>
        </BoxedContainer>
        <Flex bgColor="white" height="48px" align="center" width="100%" justify="center">
          <Text textAlign="center" color="secondary.SECOND" size="2xs">
            bravo, vous êtes arrivé.e jusqu’à la fin
          </Text>
        </Flex>
      </FullWidthContainer>
    </footer>
  )
}

export default Footer
