import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Image,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
// import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { s3ImageURL } from '@miimosa/common'
import { Button, Icon, Link, UserContext } from '@miimosa/design-system/components'
import Burger from '@miimosa/design-system/components/icons/Burger'

import MobileNav from './MobileNav'
// const DynamicHeaderAvatar = dynamic(() => import('./avatar'), { ssr: false })

const Header = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const user = useContext(UserContext)
  const baseHeaderHeight = 72
  const { query } = useRouter()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const menuTopMargin = `${baseHeaderHeight - 1}px`

  useEffect(() => onClose(), [onClose, query])

  return (
    <Box id="top" as="nav" position="sticky" top="0" left="0" right="0" zIndex="1000" bgColor="secondary.TERTIARY">
      <Flex
        color="white"
        height={`${baseHeaderHeight}px`}
        mx={{ base: 0, md: '120px' }}
        align="center"
        justify="space-between"
      >
        {isMobile && <Box width="92px" />}

        <Flex
          flex="1 1 0%"
          align="center"
          display={{ base: 'none', md: 'flex' }}
          justifyContent="flex-start"
          columnGap={5}
        >
          <Link size="lg" as="a" href="/" fontWeight="bold" mr="12">
            <Image src={s3ImageURL('logo-classe-verte-white.png')} width="auto" height="38px" alt="logo innocent" />
          </Link>
          <Link size="lg" as="a" href="/projects" fontWeight="bold">
            projets soutenus depuis 3 ans
          </Link>
          <Link size="lg" as="a" href="/manifest" fontWeight="bold">
            notre manifeste
          </Link>
          <Link size="lg" as="a" href="/faq" fontWeight="bold">
            faq
          </Link>
        </Flex>

        {isMobile && (
          <Flex align="center" justify="center" mt={{ base: '20px', md: 0 }} flex="1 1 0%">
            <Link
              href="/"
              variant="ghost"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="92px"
              height="92px"
              borderRadius="50%"
              bgColor="secondary.TERTIARY"
            >
              <Icon name="LogoInnocent" color="white" size="xs" />
            </Link>
          </Flex>
        )}

        {/* {!isMobile && <DynamicHeaderAvatar user={user} />} */}
        {isMobile && (
          <Button onClick={onToggle} aria-label={'Toggle Navigation'} variant="ghost" size="iconMD" width="92px">
            <Burger isOpen={isOpen} color="bg.0" />
          </Button>
        )}
      </Flex>
      {isMobile && (
        <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
          <DrawerContent
            marginTop={menuTopMargin}
            maxHeight={`calc(100% - ${menuTopMargin})`}
            bgColor="secondary.TERTIARY"
          >
            <DrawerBody padding="0">
              <MobileNav user={user} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  )
}

export default Header
