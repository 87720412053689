import { Avatar, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

import type { PublicUser } from '@miimosa/common/types'
import { Link, LinkV2 } from '@miimosa/design-system/components'

interface Props {
  user: PublicUser | undefined
}

const MobileNav: FC<Props> = ({ user }) => {
  return (
    <Flex direction="column" justifyContent="space-between" color="bg.0" px="4" pt="48px" pb="32px" height="100%">
      <Flex direction="column">
        {user && (
          <Flex>
            <Avatar mr="16px" size="lg" mb="12" name={user?.initials} src={user?.avatar ?? undefined} />
            <Heading size="md" color="white">
              {user?.full_name}
            </Heading>
          </Flex>
        )}

        <Link href="/projects" size="lg" mb="24px">
          projets soutenus depuis 3 ans
        </Link>
        <Link href="/manifest" size="lg" mb="24px">
          notre manifeste
        </Link>
        <Link href="/faq" size="lg" mb="24px">
          faq
        </Link>
        {user && (
          <>
            <Text size="lg" mb="24px">
              mon compte
            </Text>
            <LinkV2 path={`/users/${user?.slug}`} size="md" mb="24px" ml="10px">
              mon profil
            </LinkV2>
            <LinkV2 path="/users/dashboard" size="md" mb="24px" ml="10px">
              mon espace
            </LinkV2>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default MobileNav
