import { sizes } from './text'

export const Link = {
  sizes,
  // Outdated, use the color attribute directly instead
  variants: {
    white: {
      color: '#fff',
    },
    black: {
      color: '#000',
    },
    small_grey: {
      color: 'primary_grey.DEFAULT',
      fontSize: 'xs',
    },
    ghost: {
      outline: null,
      boxShadow: null,
      _hover: {
        outline: null,
        boxShadow: null,
      },
      _focus: {
        outline: null,
        boxShadow: null,
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    color: 'gray.700',
  },
}
