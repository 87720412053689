import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: { base: '1em', lg: '1.5rem' },
    lineHeight: { base: '1.5rem', lg: '1.75rem' },
    fontFamily: 'innocent-headline_thin',
    fontWeight: 400,
  },
  md: {
    fontSize: { base: '1.25rem', lg: '1.2rem' },
    lineHeight: { base: '1.5rem', lg: '1.4rem' },
    fontWeight: 700,
  },
  lg: {
    fontSize: { base: '1.5rem', lg: '2rem' },
    lineHeight: { base: '1.75rem', lg: '2.25rem' },
    fontWeight: 700,
  },
  xl: {
    fontSize: { base: '2rem', lg: '2.5rem' },
    lineHeight: { base: '2.75rem', lg: '2.75rem' },
    fontWeight: 700,
  },
  '2xl': {
    fontSize: { base: '3rem', lg: '3.5rem' },
    lineHeight: { base: '3.125rem', lg: '3.625rem' },
    fontWeight: 700,
  },
}

export const Heading = {
  sizes,
  variants: {
    withBackline: {
      d: 'inline-block',
      bgGradient: 'linear(to-t, transparent 5%, secondary.DEFAULT 0%, secondary.DEFAULT 40%, transparent 0%)',
      px: '10px',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    color: 'text',
  },
}
