import { SystemStyleObject } from '@chakra-ui/react'

const baseText = {
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '22px',
}

function iconSize(w: number, p: number): SystemStyleObject {
  return {
    w: `${w}px`,
    h: `${w}px`,
    p: `${p}px`,
  }
}

const sizes: Record<string, SystemStyleObject> = {
  xs: {
    ...baseText,
    h: '24px',
    padding: '9px 18px',
  },
  sm: {
    ...baseText,
    h: '34px',
    padding: '9px 24px',
  },
  md: {
    ...baseText,
    h: '48px',
    padding: '13px 38px',
  },
  iconXS: iconSize(24, 4),
  iconSM: iconSize(32, 6),
  iconMD: iconSize(40, 8),
  iconLG: iconSize(48, 10),
  iconXL: iconSize(56, 12),
  icon2XL: iconSize(64, 14),
  icon3XL: iconSize(72, 16),
}

const baseButton: SystemStyleObject = {
  border: 0,
  outline: 0,
  _focus: {
    outline: 0,
    boxShadow: 0,
  },
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
}

const primary: SystemStyleObject = {
  ...baseButton,
  backgroundColor: 'primary.DEFAULT',
  color: 'white',
  _active: {
    backgroundColor: 'primary.HOVER',
  },
  _hover: {
    backgroundColor: 'primary.HOVER',
  },
  _disabled: {
    backgroundColor: 'primary.DISABLED',
    opacity: 1,
  },
}

const primaryW: SystemStyleObject = {
  ...baseButton,
  backgroundColor: 'white',
  color: 'secondary.TERTIARY',
  _active: {
    backgroundColor: '#A1D884',
  },
  _hover: {
    backgroundColor: '#A1D884',
  },
  _disabled: {
    backgroundColor: '#99BCB2',
    opacity: 1,
  },
}

const secondary: SystemStyleObject = {
  ...baseButton,
  backgroundPosition: '0 center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  bgColor: 'transparent',
  color: '#E87722',
  _active: { color: '#FF9E1B' },
  _hover: { color: '#FF9E1B' },
  _disabled: { color: '#FFC9A0' },
}

const secondaryW: SystemStyleObject = {
  ...baseButton,
  backgroundPosition: '0 center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  bgColor: 'transparent',
  color: 'white',
}

const iconG: SystemStyleObject = {
  ...primary,
  _hover: {
    bg: 'white.15',
  },
  _active: {
    bg: 'white.15',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'white',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
}

const iconW: SystemStyleObject = {
  ...baseButton,
  bg: 'white',
  color: 'primary.DEFAULT',
  border: 0,
  _active: {
    bg: 'primary.LIGHTEN',
  },
  _hover: {
    bg: 'primary.LIGHTEN',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'primary.DEFAULT',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _disabled: {
    color: 'white',
    bg: 'primary.DISABLED',
    opacity: 1,
  },
}

export const Button = {
  sizes,
  variants: {
    primary,
    primaryW,
    secondary,
    secondaryW,
    iconG,
    iconW,
    ghost: {
      border: null,
      outline: null,
      boxShadow: 'none',
      bg: null,
      fontWeight: null,
      borderRadius: null,
      minWidth: null,
      w: null,
      padding: 0,
      _active: {
        bg: null,
      },
      _hover: {
        bg: null,
      },
      _focus: {
        outline: null,
        boxShadow: null,
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: '',
    colorScheme: '',
  },
}
