import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Discover',
  viewBox: '0 0 38 20',
  path: (
    <>
      <path
        d="M4.5 10.0694C4.5 9.53012 4.94772 9.09296 5.5 9.09296H30.5C31.0523 9.09296 31.5 9.53012 31.5 10.0694C31.5 10.6087 31.0523 11.0458 30.5 11.0458H5.5C4.94771 11.0458 4.5 10.6087 4.5 10.0694Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7929 16.214C25.4024 15.8327 25.4024 15.2144 25.7929 14.8331L30.7426 10L25.7929 5.16688C25.4024 4.78556 25.4024 4.16731 25.7929 3.78599C26.1834 3.40467 26.8166 3.40467 27.2071 3.78599L32.864 9.30955C33.2545 9.69088 33.2545 10.3091 32.864 10.6904L27.2071 16.214C26.8166 16.5953 26.1834 16.5953 25.7929 16.214Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    w: '28px',
    h: '20px',
  },
})
