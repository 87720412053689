import { useBreakpointValue } from '@chakra-ui/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'

import { s3ImageURL } from '@miimosa/common'
import ButtonContext from '@miimosa/design-system/components/Button/button-context'

import Button from '../Button'
import Footer from '../Footer'
import Header from '../Header'

const fullWidthRoutesMobile = ['/video']

const shouldHideHeadAndFooterOnMobile = (route: string): boolean => {
  return fullWidthRoutesMobile.includes(route)
}

const Layout = ({ children }: { children: ReactElement }) => {
  const { route } = useRouter()
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const hideHeadAndFooterOnlyOnMobile = isMobile && shouldHideHeadAndFooterOnMobile(route)

  return (
    <>
      <Head>
        <meta name="description" content="Innocent." />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href={s3ImageURL('favicon.ico')} />
        <link rel="icon" type="image/png" href={s3ImageURL('favicon.png')} sizes="64x64" />
      </Head>
      <ButtonContext.Provider value={Button}>
        {!hideHeadAndFooterOnlyOnMobile && <Header />}
        <main>{children}</main>
        {!hideHeadAndFooterOnlyOnMobile && <Footer />}
      </ButtonContext.Provider>
    </>
  )
}

export default Layout
