import '../styles/globals.css'
import '../styles/fonts.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { register } from 'timeago.js'

import UserContext from '@miimosa/design-system/components/UserContext'
import { useV2User } from '@miimosa/design-system/lib/hooks'
import { timeagoFR } from '@miimosa/design-system/lib/i18n/timeago'

import '@styles/ck.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-pagination/assets/index.css'
import { theme } from '../themes'

import Layout from '@components/Layout'

function FallbackComponent() {
  return <div>An error has occured</div>
}

// register your locale with timeago
register('fr', timeagoFR)

function MyApp({ Component, pageProps }: AppProps & any) {
  useEffect(() => {
    if (process.env.NODE_ENV == 'production') {
      TagManager.initialize({ gtmId: 'GTM-T9Z5WK8' })
    }
  }, [])

  const user = useV2User('innocent_fr')

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <UserContext.Provider value={user}>
        <ChakraProvider theme={theme}>
          <Layout>{<Component {...pageProps} />}</Layout>
        </ChakraProvider>
      </UserContext.Provider>
    </Sentry.ErrorBoundary>
  )
}

export default appWithTranslation(MyApp)
