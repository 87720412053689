import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Discover',
  viewBox: '0 0 188 48',
  d: 'M58.4991 48C77.53 47.7868 96.623 46.9342 115.654 47.1473C119.51 47.1473 123.304 47.2539 127.159 47.2539C130.456 47.2539 133.814 47.3605 137.11 47.4671C141.464 47.5737 145.817 47.6802 150.108 47.4671C153.653 47.3605 157.198 47.1473 160.743 47.0407C165.035 47.0407 169.45 47.2539 173.617 45.6551C177.411 44.2695 181.578 41.6049 184.687 37.4481C187.299 33.9308 188.419 26.7896 187.859 21.2471C186.802 10.1623 179.774 5.36593 174.239 2.7013C166.589 -0.922592 158.815 0.0366748 150.979 0.35643C142.272 0.676186 133.565 0.995941 124.858 1.20911C107.444 1.63545 90.0928 1.63545 72.679 1.3157C69.0096 1.20911 65.3403 1.20911 61.7331 0.995941C51.036 0.569601 40.3389 1.20911 29.6419 1.95521C20.8105 2.48813 10.5488 0.995942 3.58326 12.0808C2.27722 14.2125 1.09556 16.664 0.411449 19.5418C-0.521436 23.8052 0.224872 28.3883 1.71749 32.0122C2.33941 33.5044 3.2101 34.7834 3.95641 35.9559C4.14299 36.2756 4.39176 36.5954 4.64053 36.8086C5.07587 37.3415 5.51122 37.8744 6.00876 38.4073C6.94165 39.4732 7.87453 40.4325 8.86961 41.2851C10.8598 42.9905 12.9121 44.3761 15.0888 45.3354C15.5242 45.5485 15.8973 45.6551 16.3327 45.8683C23.858 48.5329 32.1917 47.6802 39.7792 47.7868C46.0606 48 52.2799 48 58.4991 48Z',
  defaultProps: {
    preserveAspectRatio: 'none',
  },
})
