import { SystemStyleObject } from '@chakra-ui/react'

export const sizes: Record<string, SystemStyleObject> = {
  '3xs': {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontFamily: 'innocent-headline_thin',
  },
  '2xs': {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },
  xs: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontFamily: 'innocent-headline_thin',
  },
  sm: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 700,
  },
  md: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: 'innocent-headline_thin',
  },
  lg: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },
  xl: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontFamily: 'innocent-headline_thin',
  },
  '2xl': {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 700,
  },
  '3xl': {
    fontSize: '1.75rem',
    lineHeight: '1.75rem',
    textAlign: 'center',
    fontWeight: 700,
  },
}

export const Text = {
  sizes,
  // Outdated, use the color attribute directly instead
  variants: {
    white: {
      color: '#fff',
    },
    black: {
      color: '#000',
    },
    small_grey: {
      color: 'primary_grey.DEFAULT',
      fontSize: 'xs',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    color: 'text',
  },
}
