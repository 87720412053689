export const Avatar = {
  sizes: {
    sm: {
      container: {
        w: '24px',
        h: '24px',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    md: {
      container: {
        w: '40px',
        h: '40px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    lg: {
      container: {
        w: '52px',
        h: '52px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    xl: {
      container: {
        w: '74px',
        h: '74px',
        fontSize: '36px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    '2xl': {
      container: {
        w: '154px',
        h: '154px',
        fontSize: '36px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
  },
  variants: {
    grey: {
      container: {
        bg: '#F5F6F1',
      },
    },
  },
  defaultProps: {
    variant: 'grey',
  },
}
