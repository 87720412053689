import { ButtonProps, Button as ChakraButton, useStyleConfig } from '@chakra-ui/react'

import Arrow from './Arrow'
import Background from './Background'
import Border from './Border'

const Button = (props: ButtonProps & { hidearrow?: 'true' }) => {
  const variant = props.variant as string
  const style = useStyleConfig('Button', props)
  if (!variant || !(variant.startsWith('primary') || variant.startsWith('secondary'))) {
    return <ChakraButton variant={variant} {...props} />
  }

  const { children, backgroundColor, color, _active, _disabled, _hover, ...leftovers } = {
    ...style,
    ...props,
  } as any as ButtonProps
  const isPrimary = variant.startsWith('primary')
  const Back = isPrimary ? Background : Border

  // Is there a better way to do this ?
  const { backgroundColor: activeBg, ...activeRest } = _active ?? {}
  const { backgroundColor: disabledBg, ...disabledRest } = _disabled ?? {}
  const { backgroundColor: hoverBg, ...hoverRest } = _hover ?? {}

  const colorProps: ButtonProps = { _active: activeRest, _disabled: disabledRest, _hover: hoverRest }

  if (isPrimary) {
    colorProps.sx = {
      _active: { '.but-bg': { color: activeBg } },
      _hover: { '.but-bg': { color: hoverBg } },
      _disabled: { '.but-bg': { color: disabledBg } },
    }
  }

  return (
    <ChakraButton
      position="relative"
      variant={variant}
      display="flex"
      color={color}
      flexDirection="row"
      backgroundColor="transparent"
      zIndex="0" // make sure we are creating a stacking context for the background
      {...colorProps}
      {...leftovers}
    >
      <Back
        w="100%"
        h="100%"
        className="but-bg"
        position="absolute"
        left="0"
        top="0"
        zIndex="-1"
        color={isPrimary ? backgroundColor : 'inherit'}
      />
      {children}
      {props.hidearrow !== 'true' && <Arrow ml="12px" />}
    </ChakraButton>
  )
}
export default Button
